@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    /*padding: 0;*/
    border: 0;
    /*font-size: 100%;
    font: inherit;*/
    vertical-align: baseline;
    box-sizing: border-box;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
}

/*ol,
ul {
    list-style: none;
}*/

blockquote,
q {
    quotes: none;
}

    blockquote:before, blockquote:after {
        content: '';
        content: none;
    }

    q:before, q:after {
        content: '';
        content: none;
    }

.scrollbar::-webkit-scrollbar-track, .page-content::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    background-color: #eee;
    border-radius: 5px;
    border: 3px solid #fff;
    box-sizing: border-box;
}

.scrollbar::-webkit-scrollbar, .page-content::-webkit-scrollbar {
    width: 8px;
    background-color: #eee;
    border-radius: 5px;
    margin-right: 20px;
}

.scrollbar::-webkit-scrollbar-thumb, .page-content::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 5px;
    border: none;
}


.eye:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    background-image: url("../images/layout/iconset.svg");
    background-size: auto 100%;
}

.list-table .lt-row .alert:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    -webkit-mask-image: url("../images/layout/iconset.svg");
    -webkit-mask-size: auto 100%;
}

.icon-0:before {
    -webkit-mask-position: 0% 0;
}

.icon-1:before {
    -webkit-mask-position: 2% 0;
}

.icon-2:before {
    -webkit-mask-position: 4% 0;
}

.icon-3:before {
    -webkit-mask-position: 6% 0;
}

.icon-4:before {
    -webkit-mask-position: 8% 0;
}

.icon-5:before {
    -webkit-mask-position: 10% 0;
}

.icon-6:before {
    -webkit-mask-position: 12% 0;
}

.icon-7:before {
    -webkit-mask-position: 14% 0;
}

.icon-8:before {
    -webkit-mask-position: 16% 0;
}

.icon-9:before {
    -webkit-mask-position: 18% 0;
}

.icon-10:before {
    -webkit-mask-position: 20% 0;
}

.icon-11:before {
    -webkit-mask-position: 22% 0;
}

.icon-12:before {
    -webkit-mask-position: 24% 0;
}

.icon-13:before {
    -webkit-mask-position: 26% 0;
}

.icon-14:before {
    -webkit-mask-position: 28% 0;
}

.icon-15:before {
    -webkit-mask-position: 30% 0;
}

.icon-16:before {
    -webkit-mask-position: 32% 0;
}

.icon-17:before {
    -webkit-mask-position: 34% 0;
}

.icon-18:before {
    -webkit-mask-position: 36% 0;
}

.icon-19:before {
    -webkit-mask-position: 38% 0;
}

.icon-20:before {
    -webkit-mask-position: 40% 0;
}

.icon-21:before {
    -webkit-mask-position: 42% 0;
}

.icon-22:before {
    -webkit-mask-position: 44% 0;
}

.icon-23:before {
    -webkit-mask-position: 46% 0;
}

.icon-24:before {
    -webkit-mask-position: 48% 0;
}

.icon-25:before {
    -webkit-mask-position: 50% 0;
}

.icon-26:before {
    -webkit-mask-position: 52% 0;
}

.icon-27:before {
    -webkit-mask-position: 54% 0;
}

.icon-28:before {
    -webkit-mask-position: 56% 0;
}

.icon-29:before {
    -webkit-mask-position: 58% 0;
}

.icon-30:before {
    -webkit-mask-position: 60% 0;
}

.icon-31:before {
    -webkit-mask-position: 62% 0;
}

.icon-32:before {
    -webkit-mask-position: 64% 0;
}

.icon-33:before {
    -webkit-mask-position: 66% 0;
}

.icon-34:before {
    -webkit-mask-position: 68% 0;
}

.icon-35:before {
    -webkit-mask-position: 70% 0;
}

.icon-36:before {
    -webkit-mask-position: 72% 0;
}

.icon-37:before {
    -webkit-mask-position: 74% 0;
}
.icon-38:before {
    -webkit-mask-position: 76% 0;
}

/*
.icon-41:before {
    -webkit-mask-position: 102.5% 0;
}

.icon-42:before {
    -webkit-mask-position: 105% 0;
}

.icon-43:before {
    -webkit-mask-position: 107.5% 0;
}

.icon-44:before {
    -webkit-mask-position: 110% 0;
}

.icon-45:before {
    -webkit-mask-position: 112.5% 0;
}

.icon-46:before {
    -webkit-mask-position: 115% 0;
}

.icon-47:before {
    -webkit-mask-position: 117.5% 0;
}

.icon-48:before {
    -webkit-mask-position: 120% 0;
}

.icon-49:before {
    -webkit-mask-position: 122.5% 0;
}

.icon-50:before {
    -webkit-mask-position: 125% 0;
}
*/
.icon {
    font-size: 0;
}

    .icon:before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        -webkit-mask-image: url("../images/layout/iconset.svg");
        -webkit-mask-size: auto 100%;
        width: 28px;
        height: 28px;
        background-color: #62B8EE;
        transition: background-color .3s ease;
    }

    .icon-white:before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        -webkit-mask-image: url("../images/layout/iconset.svg");
        -webkit-mask-size: auto 100%;
        width: 28px;
        height: 28px;
        background-color: white;
    }
.icon-black:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    -webkit-mask-image: url("../images/layout/iconset.svg");
    -webkit-mask-size: auto 100%;
    width: 28px;
    height: 28px;
    background-color: black;
    transition: background-color .3s ease;
}


.icon-orange:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    -webkit-mask-image: url("../images/layout/iconset.svg");
    -webkit-mask-size: auto 100%;
    width: 28px;
    height: 28px;
    background-color: #F37402;
    transition: background-color .3s ease;
}

.icon-gray:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    -webkit-mask-image: url("../images/layout/iconset.svg");
    -webkit-mask-size: auto 100%;
    width: 28px;
    height: 28px;
    background-color: #BDBDBD;
    transition: background-color .3s ease;
}

.icon.w2:before {
    width: 60px;
}

.icon-before:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    background-image: url("../images/layout/iconset.svg");
}

.icon-after:after {
    content: "";
    display: inline-block;
    vertical-align: middle;
    background-image: url("../images/layout/iconset.svg");
}

.fz-hero {
    font-size: 36px;
    font-weight: 400;
    display: block;
    line-height: 1.125em;
}

@media (min-width: 1380px) {
    .fz-hero {
        font-size: 48px;
    }
}

.fz-A {
    font-size: 26px;
    font-weight: 400;
    display: block;
    line-height: 1.25em;
    margin-bottom: 2vw;
}

@media (min-width: 1380px) {
    .fz-A {
        font-size: 32px;
    }
}

.fz-B {
    font-size: 1.375rem;
    font-weight: 400;
    display: block;
    line-height: 1.25em;
    margin-bottom: 2vw;
}

@media (min-width: 1380px) {
    .fz-B {
        font-size: 1.5rem;
    }
}

@media (min-width: 1700px) {
    .fz-B {
        font-size: 1.75rem;
    }
}

.fz-B.has-border {
    padding-left: 0.75em;
    border-left: 5px solid #62B8EE;
}

@media (min-width: 1380px) {
    .fz-B.has-border {
        border-left: 8px solid #62B8EE;
    }
}

.fz-C, .block-area .form-item .field {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.25em;
}

@media (min-width: 1180px) {
    .fz-C, .block-area .form-item .field {
        margin-top: 10px;
        margin-left: 5px;
        font-weight: 800;
    }
}

@media (min-width: 1380px) {
    .fz-C, .block-area .form-item .field {
        font-size: 1rem;
    }
}

/*@media (min-width: 1700px) {
    .fz-C, .block-area .form-item .field {
        font-size: 20px;
    }
}
*/
.fz-C {
    display: block;
    margin-bottom: 2vw;
}

.fz-D {
    font-size: 16px;
    font-weight: 500;
    display: block;
    line-height: 1.25em;
    margin-bottom: 1em;
}

.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: none;
    outline: none;
    box-shadow: none;
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    font-weight: 500;
    padding: 0 1.5em;
    box-sizing: border-box;
    border: 2px solid #62B8EE;
    background-color: #62B8EE;
    color: #fff;
    border-radius: 32px;
    white-space: nowrap;
    justify-content: center;
    transition: all .25s ease;
    transition-property: background-color, border-color, color, transform;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    height: 36px;
    font-size: .9375rem;
    font-family: "Noto Sans TC", "PingFang", "PingFangTC", "Microsoft JhengHei", Helvetica, Arial, sans-serif;
}

    .btn + .btn {
        margin-left: 0.5em;
    }

@media (min-width: 576px) {
    .btn + .btn {
        margin-left: 1em;
    }
}

.btn span {
    font-weight: 500;
    font-family: "Noto Sans TC", "PingFang", "PingFangTC", "Microsoft JhengHei", Helvetica, Arial, sans-serif;
    line-height: 1em;
}

.btn.disabled {
    cursor: not-allowed;
    color: #BDBDBD;
    border-color: #BDBDBD;
}

    .btn.disabled .icon:before {
        background-color: #BDBDBD;
    }

    .btn.disabled span {
        color: #BDBDBD;
    }

@media (min-width: 576px) {
    .btn {
        padding: 0 2em;
    }
}

@media (min-width: 1180px) {
    .btn {
        height: 42px;
        font-size: 1rem;
    }
}

@media (min-width: 1380px) {
    .btn {
        height: 48px;
        font-size: 1.125rem;
    }
}

@media (min-width: 980px) {
    .btn {
        min-width: 180px;
    }
}

.btn span {
    display: block;
    white-space: nowrap;
    transition: color .35s ease;
    pointer-events: none;
    box-sizing: border-box;
}

.btn:hover, .btn:active {
    background-color: #1D436F;
    border-color: #1D436F;
}

.btn.btn-border {
    background-color: #FAFAFA;
    color: #62B8EE;
}

    .btn.btn-border:hover, .btn.btn-border:active {
        background-color: #BDBDBD;
        border-color: #BDBDBD;
    }

        .btn.btn-border:hover span, .btn.btn-border:active span {
            color: #fff;
        }

.btn.btn-small {
    border-radius: 10px;
    height: 28px;
    padding: 0 .75em;
    min-width: auto;
    font-size: 14px;
}

@media (min-width: 1180px) {
    .btn.btn-small {
        height: 28px;
        font-size: 14px;
    }
}

@media (min-width: 1380px) {
    .btn.btn-small {
        height: 28px;
        font-size: 14px;
    }
}

@media (min-width: 1700px) {
    .btn.btn-small {
        height: 28px;
        font-size: 14px;
    }
}

.btn.btn-small span {
    font-weight: normal;
}

.btn.btn-small + .btn-icon {
    margin-left: 30px;
}

@media (hover: hover) {
    .btn.btn-small:hover {
        background-color: #1D436F;
        border-color: #1D436F;
    }
}

.btn.btn-middle {
    border-radius: 10px;
    height: 32px;
    padding: 0 .9em;
    min-width: auto;
    font-size: 14px;
}

@media (min-width: 1180px) {
    .btn.btn-middle {
        height: 32px;
        font-size: 14px;
    }
}

@media (min-width: 1380px) {
    .btn.btn-middle {
        height: 32px;
        font-size: 16px;
    }
}

@media (min-width: 1700px) {
    .btn.btn-middle {
        height: 36px;
        font-size: 16px;
    }
}

.btn.btn-middle span {
    font-weight: normal;
}

.btn.btn-middle + .btn-icon {
    margin-left: 30px;
}

@media (hover: hover) {
    .btn.btn-middle:hover {
        background-color: #1D436F;
        border-color: #1D436F;
    }
}

.btn.btn-icon {
    height: 36px;
    padding: 0;
    min-width: auto;
    background-color: transparent;
    border-color: transparent;
}

    .btn.btn-icon.rotate {
        transform: rotate(180deg);
    }

@media (min-width: 1180px) {
    .btn.btn-icon {
        height: 42px;
        width: 42px;
    }
}

@media (min-width: 1380px) {
    .btn.btn-icon {
        height: 48px;
        width: 48px;
    }
}

@media (min-width: 1700px) {
    .btn.btn-icon .icon:before {
        width: 30px;
        height: 30px;
    }
}

@media (hover: hover) {
    .btn.btn-icon:hover .icon:before {
        background-color: #1D436F;
    }
}

.btn.btn-large {
    height: 32px;
    padding: 0 1.5em;
    border-radius: 15px;
}

    .btn.btn-large span {
        font-weight: normal;
    }

@media (min-width: 1180px) {
    .btn.btn-large {
        height: 40px;
    }
}

@media (min-width: 1380px) {
    .btn.btn-large {
        height: 48px;
    }
}

.btn.btn-dark {
    background-color: #1D436F;
    border-color: #1D436F;
}

    .btn.btn-dark:hover, .btn.btn-dark:active {
        background-color: #000;
        border-color: #000;
    }

        .btn.btn-dark:hover span, .btn.btn-dark:active span {
            color: #fff;
        }

.btn.full {
    width: 100%;
    max-width: 100%;
}

input,
textarea,
select {
    border: none;
    background: none;
    outline: none;
    box-shadow: none;
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    border: 1px solid #BDBDBD;
    font-size: 1rem;
    transition: border-color .3s ease;
    height: 32px;
    box-sizing: border-box;
    background-color: #fff;
    font-family: "Noto Sans TC", "PingFang", "PingFangTC", "Microsoft JhengHei", Helvetica, Arial, sans-serif;
    color: #757575;
}

    input.right,
    textarea.right,
    select.right {
        text-align: right;
    }

    input.center,
    textarea.center,
    select.center {
        text-align: center;
    }

    input.radius,
    textarea.radius,
    select.radius {
        border-radius: 20px !important;
    }

    input.error,
    textarea.error,
    select.error {
        border-color: #F37402;
        border-width: 2px;
    }

        input.error::placeholder,
        textarea.error::placeholder,
        select.error::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #F37402;
            opacity: 1;
            /* Firefox */
        }

        input.error:-ms-input-placeholder,
        textarea.error:-ms-input-placeholder,
        select.error:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #F37402;
        }

        input.error::-ms-input-placeholder,
        textarea.error::-ms-input-placeholder,
        select.error::-ms-input-placeholder {
            /* Microsoft Edge */
            color: #F37402;
        }

    input:focus,
    textarea:focus,
    select:focus {
        border-color: #1D436F;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    }

    input:disabled,
    textarea:disabled,
    select:disabled {
        background-color: rgba(224, 224, 224, 0.6);
        color: #888;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        cursor: not-allowed;
    }

    input:read-only,
    textarea:read-only,
    select:read-only {
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
/*        cursor: not-allowed;*/
    }

@media (min-width: 1700px) {
    input,
    textarea,
    select {
        height: 36px;
    }
}

textarea {
    resize: none;
    border-radius: 8px;
    height: auto;
    padding: 0.5em;
    box-sizing: border-box;
    width: 100%;
}

select {
    border-radius: 20px;
    width: 100%;
    padding-left: 1em;
    padding-right: 1em;
    background: #fff url("../images/layout/arrow-select.svg") no-repeat right 16px center;
    background-size: 15px auto;
    font-family: "Noto Sans TC", "PingFang", "PingFangTC", "Microsoft JhengHei", Helvetica, Arial, sans-serif;
    font-weight: normal;
}

input[type='text'], input[type='email'], input[type='tel'], input[type='number'], input[type='password'], input[type='date'], input[type='datetime-local'], input[type='time'], input[type='search'], input[type='file'], input[type='month'] {
    border-radius: 20px;
    width: 100%;
    padding-left: 1em;
    padding-right: 1em;
}

input[type='radio'], input[type='checkbox'] {
    display: none;
}

    input[type='radio'] + label, input[type='checkbox'] + label {
        cursor: pointer;
        display: flex;
        align-items: center;
    }

        input[type='radio'] + label:before, input[type='checkbox'] + label:before {
            content: "";
            display: block;
            width: 1.1rem;
            height: 1.1rem;
            border: 2px solid #BDBDBD;
            flex-shrink: 0;
            margin-right: 0.5em;
            border-radius: 3px;
            transition: all .3s ease;
            transition-property: border-color, background-color;
            background-color: #fff;
        }

        input[type='radio'] + label.orange, input[type='checkbox'] + label.orange {
            background-color: #F37402;
            color: #fff;
            padding: 5px 10px;
            border-radius: 8px;
            width: max-content;
            font-size: 16px;
            user-select: none;
            -moz-user-select: none;
            -webkit-user-select: none;
        }

    input[type='radio']:checked + label, input[type='checkbox']:checked + label {
        color: #000;
    }

        input[type='radio']:checked + label:before, input[type='checkbox']:checked + label:before {
            background: #F37402 url("../images/layout/check.svg") no-repeat center;
            border-color: #F37402;
            background-size: 80% auto;
        }

        input[type='radio']:checked + label.orange, input[type='checkbox']:checked + label.orange {
            color: #fff;
            background-color: #62B8EE;
        }

            input[type='radio']:checked + label.orange:before, input[type='checkbox']:checked + label.orange:before {
                background-color: #62B8EE;
                border-color: #62B8EE;
            }

    input[type='radio']:checked:disabled + label:before, input[type='checkbox']:checked:disabled + label:before {
        background-color: #F37402;
    }

    input[type='radio']:disabled + label, input[type='checkbox']:disabled + label {
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        cursor: not-allowed;
    }

        input[type='radio']:disabled + label:before, input[type='checkbox']:disabled + label:before {
            background-color: rgba(224, 224, 224, 0.6);
            color: #888;
        }

    input[type='radio'] + label:before {
        border-radius: 50%;
    }

/*.eye {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
}

    .eye:before {
        width: 28px;
        height: 28px;
        background-size: auto 100%;
        background-position: 92.4% 0;
    }

    .eye.active:before {
        background-size: auto 100%;
        background-position: 95% 0;
    }*/
.eye {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
}

    .eye:before {
        width: 28px;
        height: 28px;
        background-size: auto 100%;
        background-position: 72% 0;
    }

    .eye.active:before {
        background-size: auto 100%;
        background-position: 74% 0;
    }
.form-item {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 5px;
    color: #424242;
    width: 100%;
    position: relative;
    align-items: center;
}

@media (min-width: 1180px) {
        .form-item {
        margin-left: 15px;
        margin-right: 15px;
    }
    .info {
        margin-left: 20px;
        margin-top: -4px;
    }
}

.form-item.independent {
    margin-left: 0;
    margin-right: 0;
}

.form-item.half {
    width: calc(50% - 30px);
}

.form-item.quarter {
    width: calc(25%);
    margin-left: 10px;
    margin-right: 10px;
}

.form-item.twenty {
    width: calc(20%);
    margin-left: 10px;
    margin-right: 10px;
}

.form-item.thirty {
    width: calc(33.3% - 20px);
    margin-left: 10px;
    margin-right: 10px;
}

.form-item .top-table {
    width: 100px;
    flex-shrink: 0;
    font-weight: 800;
    text-align: center;
}

.top-value {
    width: calc(100% - 100px);
}

/*@media (min-width: 1180px) {
    .form-item.half {
        width: calc(50% - 30px);
    }
}*/

.form-item.disabled .field {
    color: #888;
}

.form-item.read-only input {
    background: none;
    padding-left: 0;
    border: none;
}

    .form-item.read-only input:focus {
        box-shadow: none;
    }

.form-item .list-table {
    width: 100%;
    margin-top: 15px;
}

.form-item .help-word {
    width: 100%;
    flex-shrink: 0;
    font-size: .75rem;
    padding-left: 130px;
    opacity: 0;
    transition: opacity .35s ease;
    visibility: hidden;
    pointer-events: none;
    color: #F37402;
}

.form-item .extra-info {
    position: absolute;
    background-color: #BDBDBD;
    border-radius: 5px;
    right: 0;
    bottom: -12px;
    font-size: 14px;
    color: #fff;
    padding: 0 0.75em;
}

.form-item .field {
    width: 130px;
    flex-shrink: 0;
    font-weight: 800;
}

/*@media (min-width: 740px) {
    .form-item .field {
        display: flex;
        align-items: center;
        width: calc(50% - 30px);
    }
}*/
.form-item .field.jcsb {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.form-item .value {
    width: calc(100% - 130px);
    flex-shrink: 0;
    display: flex;
    flex-wrap: wrap;
    position: relative;
}

    .form-item .value > div {
        margin-right: 1em;
    }

        .form-item .value > div.btn-area {
            margin-right: 0;
            margin-left: 1em;
            justify-content: space-between;
            align-items: flex-start;
        }

            .form-item .value > div.btn-area .info {
                font-size: 14px;
                color: #424242;
                margin-top: -0.75em;
                padding-left: 1em;
            }

@media (min-width: 1700px) {
    .form-item .value > div.btn-area .info {
        padding-left: 1.5em;
    }
}

.form-item .value input + div {
    margin-left: 1em;
    margin-right: 0;
}

.form-item .value.multi-inline {
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
}
    .form-item .value.multi-inline.discount {
        display: grid;
        flex-wrap: wrap;
        grid-template-columns: repeat(5, 1fr);
        grid-row-gap: 10px;
    }

    .form-item .value.multi-inline > div,
    .form-item .value.multi-inline > input,
    .form-item .value.multi-inline > select {
        flex: auto;
    }

    .form-item .value.multi-inline > input {
        flex: 5;
    }

        .form-item .value.multi-inline > input + select {
            flex: 3;
            margin-left: 10px;
        }

        .form-item .value.multi-inline > input + .btn {
            margin-left: 10px;
        }

    .form-item .value.multi-inline .btn-area {
        flex: 1;
        width: max-content;
    }

    .form-item .value.multi-inline .info {
        background-color: #BDBDBD;
        padding: 0 0.75em;
        border-radius: 8px;
        margin-left: 1em;
        margin-right: 0;
        color: #fff;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        font-weight: normal;
    }

.form-item .value .password {
    position: relative;
    width: 100%;
    margin: 0;
}

    .form-item .value .password .eye {
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
    }

.form-item .datalist {
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    position: absolute;
    z-index: 9;
    background-color: #fff;
    width: 100%;
    overflow-y: auto;
    max-height: 200px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    top: 32px;
    pointer-events: none;
    opacity: 0;
}

@media (min-width: 1700px) {
    .form-item .datalist {
        top: 36px;
    }
}

.form-item .datalist li {
    padding: 0.25em 1em;
    font-size: .9375rem;
    display: flex;
    cursor: pointer;
}

    .form-item .datalist li > span {
        flex-shrink: 0;
        pointer-events: none;
    }

        .form-item .datalist li > span.bold {
            font-weight: 500;
        }

        .form-item .datalist li > span.flex-1 {
            flex: 1;
        }

        .form-item .datalist li > span.flex-2 {
            flex: 2;
        }

        .form-item .datalist li > span.flex-3 {
            flex: 3;
        }

        .form-item .datalist li > span.flex-4 {
            flex: 4;
        }

        .form-item .datalist li > span.flex-5 {
            flex: 5;
        }

    .form-item .datalist li:hover, .form-item .datalist li:active {
        background-color: rgba(98, 184, 238, 0.2);
    }

.form-item.has-datalist.show-datalist .datalist {
    pointer-events: auto;
    opacity: 1;
}

.form-item.col {
    flex-direction: column;
}

    .form-item.col .field {
        width: 100%;
    }

    .form-item.col .value {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

        .form-item.col .value img {
            max-width: 160px;
            height: auto;
            display: block;
            margin: 20px auto 0;
        }

.form-item.small-field .field {
    width: 48px;
}

.form-item.small-field .value {
    width: calc(100% - 48px);
}

.form-item .notification {
    color: #fff;
    font-size: 15px;
    text-align: center;
    width: 100%;
    margin-bottom: 28px;
}

.form-item.no-field input,
.form-item.no-field select {
    padding-left: 1em !important;
    width: 100%;
}

.form-item.no-field .value {
    width: 100%;
}

.form-item.no-field .field {
    display: none;
}

.form-item.no-field .help-word {
    padding-left: 10px;
}

.form-item.no-help-word .help-word {
    display: none;
}

.form-item.inline {
    margin: 0;
}

@media (min-width: 980px) {
    .form-item.short {
        width: calc(100% - 150px) !important;
        flex-shrink: 0;
        margin-left: auto;
        margin-right: auto;
    }
}

.form-item.feedback {
    padding-top: 30px;
}

    .form-item.feedback .value {
        display: flex;
        justify-content: flex-end;
    }

        .form-item.feedback .value textarea {
            width: 380px;
        }

.form-item.lg {
    position: relative;
    margin-bottom: 28px;
}

    .form-item.lg input,
    .form-item.lg select {
        height: 48px;
        border-radius: 12px;
        padding-left: 70px;
    }

@media (min-width: 1380px) {
    .form-item.lg input,
    .form-item.lg select {
        height: 56px;
        border-radius: 15px;
        font-size: 1.125rem;
    }
}

.form-item.lg .value {
    width: 100%;
}

.form-item.lg .field {
    position: absolute;
    z-index: 3;
    left: 0;
    top: 0;
    width: 60px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (min-width: 1380px) {
    .form-item.lg .field {
        height: 56px;
    }
}

.form-item.lg .field:after {
    content: "";
    display: block;
    width: 1px;
    border-right: 1px solid #BDBDBD;
    right: 0;
    top: 10%;
    height: 80%;
    position: absolute;
}

.form-item.lg .help-word {
    position: absolute;
    right: 0;
    font-size: 16px;
    width: max-content;
    height: 100%;
    display: flex;
    align-items: center;
    padding-right: 1em;
}

.form-item.lg.has-fn .help-word {
    right: 2em;
}

.form-item.must .field {
    position: relative;
}

    .form-item.must .field:before {
        content: "✽";
        color: #F37402;
        letter-spacing: 0;
        position: absolute;
        transform: translateX(-100%);
    }

.form-item.show-help .help-word {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
}

.form-item.no-field .field {
    display: none;
}

.form-item.no-field .value {
    width: 100%;
}

.form-item.error .help-word {
    color: #F37402;
}

.form-item.error input[type='text'], .form-item.error input[type='email'], .form-item.error input[type='tel'], .form-item.error input[type='number'], .form-item.error input[type='password'], .form-item.error input[type='date'], .form-item.error input[type='search'], .form-item.error input[type='file'], .form-item.error input[type='month'] {
    border-color: #F37402;
    color: #F37402;
}

    .form-item.error input[type='text']:focus, .form-item.error input[type='email']:focus, .form-item.error input[type='tel']:focus, .form-item.error input[type='number']:focus, .form-item.error input[type='password']:focus, .form-item.error input[type='date']:focus, .form-item.error input[type='search']:focus, .form-item.error input[type='file']:focus, .form-item.error input[type='month']:focus {
        border-color: #F37402;
    }

.form-item.error input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #F37402;
    opacity: 1;
    font-family: "Noto Sans TC", "PingFang", "PingFangTC", "Microsoft JhengHei", Helvetica, Arial, sans-serif;
    font-weight: normal;
    /* Firefox */
}

.form-item.error input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #F37402;
    font-family: "Noto Sans TC", "PingFang", "PingFangTC", "Microsoft JhengHei", Helvetica, Arial, sans-serif;
    font-weight: normal;
}

.form-item.error input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #F37402;
    font-family: "Noto Sans TC", "PingFang", "PingFangTC", "Microsoft JhengHei", Helvetica, Arial, sans-serif;
    font-weight: normal;
}

.form-item.error select {
    border-color: #F37402;
}

.form-item.error .dropmenu-container .dropmenu-value {
    border-color: #F37402;
    color: #F37402;
}

.form-item.col {
    flex-direction: column;
}

    .form-item.col .field {
        width: 100%;
    }

    .form-item.col .value {
        width: 100%;
    }

.form-item.jcfe {
    justify-content: flex-end;
}

.form-item.btn-area {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 40px;
}

    .form-item.btn-area.sp {
        justify-content: space-between;
    }

@media (min-width: 1180px) {
    .form-container {
        display: flex;
        flex-wrap: wrap;
        margin-left: -15px;
        margin-right: -15px;
    }
}

.form-container.inline {
    display: flex;
    align-items: flex-start;
}

    .form-container.inline .form-item {
        flex: 1;
    }

        .form-container.inline .form-item.btn-area {
            max-width: max-content;
            flex: auto;
            margin-top: 0;
        }

        .form-container.inline .form-item .btn {
            min-width: auto;
        }

        .form-container.inline .form-item .field {
            width: 50px;
            flex-shrink: 0;
        }

            .form-container.inline .form-item .field.big {
                width: 80px;
                flex-shrink: 0;
            }

        .form-container.inline .form-item .value {
            width: calc(100% - 50px);
        }

            .form-container.inline .form-item .value.read {
                width: calc(50%);
            }

.form-container + hr {
    margin-top: 0;
}

.block-area {
    border: 1px solid #E0E0E0;
    width: calc(100% - 30px);
    margin: 0 auto 4.5vw;
}

@media (min-width: 1700px) {
    .block-area {
        margin-bottom: 3vw;
    }
}

.block-area .row.field {
    background-color: #eee;
    display: flex;
    justify-content: space-between;
    padding: 8px 15px;
    min-height: 48px;
}

@media (min-width: 1380px) {
    .block-area .row.field {
        padding: 12px 20px;
    }
}

@media (min-width: 1700px) {
    .block-area .row.field {
        padding: 12px 25px;
        min-height: 54px;
    }
}

.block-area .row.field .left {
    font-size: 18px;
    font-weight: 500;
}

    .block-area .row.field .left span + span {
        padding-left: 2em;
    }

.block-area .form-item {
    padding: 0 40px;
    margin: 0;
}

    .block-area .form-item .field {
        border-bottom: 1px solid #BDBDBD;
        padding: 1em 0;
    }

    .block-area .form-item .value {
        justify-content: flex-start;
        padding-top: 1em;
    }

        .block-area .form-item .value > div {
            width: 20%;
            flex-shrink: 0;
            margin: 0 0 1em 0;
        }

.modal-container {
    background-color: #FAFAFA;
    border-radius: 16px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    padding: 3.33vw 4.3vw;
    width: clamp(500px, 50%, 680px);
    transition: all .3s ease;
    opacity: 0;
    transform-style: preserve-3d;
    transform: translateY(-80px) perspective(1000px) rotateX(30deg);
}

@media (min-width: 1380px) {
    .modal-container {
        padding: 48px 4.3vw;
    }
}

.modal-container .icon-holder {
    width: 20%;
    position: relative;
    margin: 0 auto;
}

    .modal-container .icon-holder:before {
        content: "";
        display: block;
        width: 100%;
        padding-bottom: 100%;
    }

    .modal-container .icon-holder .icon {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
    }
        .modal-container .icon-holder .icon:before {
            width: 100%;
            height: 100%;
        }

    

.has-modal {
    overflow: hidden;
}

.modal {
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 20;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(29, 67, 111, 0.6);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
    transition: all .4s ease;
    transition-property: opacity;
    overflow-y: auto;
}

    .modal .close {
        position: absolute;
        right: 10px;
        top: 10px;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        cursor: pointer;
    }

        .modal .close .icon {
            width: 20px;
            height: 20px;
            pointer-events: none;
        }

            .modal .close .icon:before {
                background-color: #BDBDBD;
                width: 100%;
                height: 100%;
            }

@media (hover: hover) {
    .modal .close .icon:before {
        transition: all .3s ease;
        transition-property: background-color, transform;
    }

    .modal .close:hover .icon:before {
        background-color: #1D436F;
        transform: scale(1.125);
    }
}

.modal.small.active {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
}

    .modal.small.active .modal-container {
        transform: none;
        opacity: 1;
        transition: all .5s ease;
    }

.modal.small .form-item {
    margin: 0 0 20px;
}

    .modal.small .form-item .value > div {
        margin-bottom: 10px;
    }

.modal.large {
    padding-bottom: 10vh;
}

    .modal.large .modal-container {
        width: clamp(880px, 70%, 1280px);
        margin-top: 10vh;
        margin-bottom: auto;
    }

.modal.active {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
}

    .modal.active .modal-container {
        transform: none;
        opacity: 1;
        transition: all .5s ease;
    }

.modal.layer-2 {
    z-index: 40;
}

.modal.layer-3 {
    z-index: 60;
}

.modal.layer-4 {
    z-index: 80;
}

.modal.layer-5 {
    z-index: 80;
}

.modal-stamp {
    position: absolute;
    width: 60px;
    height: 60px;
    font-size: 1rem;
    font-weight: 500;
    border: 1px solid #F37402;
    border-radius: 50%;
    padding: 4px;
    color: #F37402;
    right: 105px;
    top: 40px;
}

    .modal-stamp .circle {
        border: 4px solid #F37402;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        transform: rotate(9deg);
    }

@media (min-width: 1180px) {
    .modal-stamp {
        font-size: 18px;
    }
}

@media (min-width: 1380px) {
    .modal-stamp {
        font-size: 1.66vw;
        width: 5.5vw;
        height: 5.5vw;
    }
}

.has-layer-2 .layer-2 {
    background-color: transparent;
    -webkit-backdrop-filter: none;
    backdrop-filter: none;
}

.has-layer-2 .layer-1 .modal-container {
    opacity: 0;
    transform-style: preserve-3d;
    transform: translateY(80px) perspective(1000px) rotateX(-30deg);
}

.has-layer-2.has-layer-3 .layer-3 {
    background-color: transparent;
    -webkit-backdrop-filter: none;
    backdrop-filter: none;
}

.has-layer-2.has-layer-3 .layer-1 .modal-container,
.has-layer-2.has-layer-3 .layer-2 .modal-container {
    opacity: 0;
    transform-style: preserve-3d;
    transform: translateY(80px) perspective(1000px) rotateX(-30deg);
}

.has-layer-2.has-layer-3.has-layer-4 .layer-4 {
    background-color: transparent;
    -webkit-backdrop-filter: none;
    backdrop-filter: none;
}

.has-layer-2.has-layer-3.has-layer-4 .layer-1 .modal-container,
.has-layer-2.has-layer-3.has-layer-4 .layer-2 .modal-container,
.has-layer-2.has-layer-3.has-layer-4 .layer-3 .modal-container {
    opacity: 0;
    transform-style: preserve-3d;
    transform: translateY(80px) perspective(1000px) rotateX(-30deg);
}

.has-layer-2.has-layer-3.has-layer-4.has-layer-5 .layer-5 {
    background-color: transparent;
    -webkit-backdrop-filter: none;
    backdrop-filter: none;
}

.has-layer-2.has-layer-3.has-layer-4.has-layer-5 .layer-1 .modal-container,
.has-layer-2.has-layer-3.has-layer-4.has-layer-5 .layer-2 .modal-container,
.has-layer-2.has-layer-3.has-layer-4.has-layer-5 .layer-3 .modal-container,
.has-layer-2.has-layer-3.has-layer-4.has-layer-5 .layer-4 .modal-container {
    opacity: 0;
    transform-style: preserve-3d;
    transform: translateY(80px) perspective(1000px) rotateX(-30deg);
}

.list-table {
    display: block;
    padding: 0;
    margin-top: 10px;
    border: 1px solid #E0E0E0;
    color: #616161;
}

    .list-table.nested {
        width: 100%;
        border: none;
    }

    .list-table .lt-row {
        display: flex;
        border-bottom: 1px solid #E0E0E0;
        align-items: center;
        overflow: hidden;
    }

        .list-table .lt-row > form {
            display: flex;
            width: 100%;
        }

        .list-table .lt-row input,
        .list-table .lt-row select {
            font-size: 14px;
        }

@media (hover: hover) {
    .list-table .lt-row {
        transition: background-color .3s ease;
    }

        .list-table .lt-row:hover {
            background-color: #f6f6f6;
        }
}

.list-table .lt-row.aifs {
    align-items: flex-start;
}

.list-table .lt-row.ovv {
    overflow: visible;
}

    .list-table .lt-row.ovv > div {
        overflow: visible;
    }

.list-table .lt-row.hide {
    display: none;
}

.list-table .lt-row:last-of-type {
    border: none;
}

.list-table .lt-row .alert {
    position: relative;
}

    .list-table .lt-row .alert:before {
        background-size: auto 100%;
        -webkit-mask-position: 83.67% 0;
        width: 16px;
        height: 16px;
        background-color: #F37402;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(115%, -50%);
    }

.list-table .lt-row > div {
    flex-shrink: 0;
    padding: 8px 4px;
    line-height: 1.5em;
    font-size: 14px;
    min-height: 48px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    overflow: hidden;
    flex-shrink: 0;
}

@media (min-width: 1380px) {
    .list-table .lt-row > div {
        padding: 12px 20px;
        padding: 12px 13px;
    }
}

@media (min-width: 1700px) {
    .list-table .lt-row > div {
        /*        padding: 5px 25px;
        font-size: 15px;
        height: 40px;*/
        padding-left: 16px;
    }
}

.list-table .lt-row > div.fn {
    padding-top: 0;
    padding-bottom: 0;
}

.list-table .lt-row > div.jcfe {
    justify-content: flex-end;
}

@media (min-width: 1380px) {
    .list-table .lt-row.intensive {
        padding-left: 8px;
        padding-right: 8px;
    }
}

.list-table .lt-row.intensive > div {
    flex-shrink: 0;
    padding: 8px 4px;
    line-height: 1.5em;
    font-size: 14px;
    min-height: 48px;
    display: flex;
    align-items: center;
}

@media (min-width: 1380px) {
    .list-table .lt-row.intensive > div {
        padding: 12px 4px;
    }
}

@media (min-width: 1700px) {
    .list-table .lt-row.intensive > div {
        padding: 12px 6px;
    }
}

.list-table .lt-row.lt-th {
    background-color: #eee;
    color: #424242;
    font-weight: 700;
}

.list-table .lt-row .text-right {
    text-align: right;
    justify-content: flex-end;
}

    .list-table .lt-row .text-right input {
        text-align: right;
    }

.list-table .lt-row .text-center {
    text-align: center;
    justify-content: center;
}
    .list-table .lt-row .text-center input {
        text-align: center;
    }

.list-table .fn-bar {
    display: flex;
    align-items: center;
    padding: 0;
}

    .list-table .fn-bar .btn + .btn {
        margin-left: 0.25em;
    }

.list-table .min-100 {
    min-width: 100px;
}

@media (min-width: 1700px) {
    .list-table .min-100 {
        min-width: 120px;
    }
}

.list-table .min-120 {
    min-width: 120px;
}

@media (min-width: 1700px) {
    .list-table .min-120 {
        min-width: 140px;
    }
}

.list-table .flex-0 {
    flex: 0.5;
}

.list-table .flex-1 {
    flex: 1;
}

.list-table .flex-2 {
    flex: 2;
}

.list-table .flex-3 {
    flex: 3;
}

.list-table .flex-4 {
    flex: 4;
}

.list-table .flex-5 {
    flex: 5;
}

.list-table .flex-6 {
    flex: 6;
}

.list-table .flex-7 {
    flex: 7;
}

.list-table .flex-8 {
    flex: 8;
}

.list-table .max1 {
    max-width: 60px;
}

@media (min-width: 1380px) {
    .list-table .max1 {
        max-width: 80px;
    }
}

.list-table input {
    border-radius: 0;
    padding-left: 0.5em;
    padding-right: 0.5em;
}

.list-table select {
    background-position: right 8px center;
    border-radius: 0;
}

.list-table a {
    color: #62B8EE;
    border-bottom: 1px solid #62B8EE;
    transition: color .15s ease;
    display: block;
    isolation: isolate;
    position: relative;
}

@media (hover: hover) {
    .list-table a:hover {
        color: #1D436F;
        border-color: #1D436F;
    }
}

.value .list-table + .btn-area {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 20px;
    width: 100%;
    margin: 0 !important;
}

.tip {
    position: relative;
    cursor: help;
}

    .tip > p {
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        display: -webkit-box;
        height: 1.5em;
        line-height: 1.5em;
        -webkit-box-orient: vertical;
    }

    .tip:before {
        content: attr(data-tip);
        display: block;
        width: 220px;
        position: absolute;
        background-color: #fff;
        border: 1px solid #62B8EE;
        padding: 10px 15px;
        font-size: 13px;
        line-height: 1.5em;
        pointer-events: none;
        opacity: 0;
        transform-origin: center bottom;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, -55px);
        border-radius: 8px;
        color: #666;
        max-height: 200px;
        overflow: hidden;
        transition: all .2s ease;
        transition-property: opacity, transform;
    }

    .tip:after {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, -55px);
        opacity: 0;
        border-width: 5px 8px 0 8px;
        border-color: #62B8EE transparent transparent transparent;
        transition: all .2s ease;
        transition-property: opacity, transform;
        position: absolute;
    }

    .tip:hover:before {
        opacity: 1;
        transform: translate(-50%, -45px);
    }

    .tip:hover:after {
        opacity: 1;
        transform: translate(-50%, -40px);
    }

.info-holder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1em 0.5em;
}

.col-table {
    display: block;
    width: 100%;
}

    .col-table + .col-table {
        margin-left: 10px;
    }

    .col-table > li {
        height: 32px;
        display: flex;
        align-items: center;
        margin-bottom: 8px;
    }

@media (min-width: 1700px) {
    .col-table > li {
        height: 36px;
    }
}

.col-table > li.space {
    height: 16px;
}

.col-table > li input,
.col-table > li select {
    width: 100%;
}

.col-table .flex {
    display: flex;
    width: 100%;
    align-items: center;
}

    .col-table .flex > div {
        flex-shrink: 0;
        width: 50%;
    }

    .col-table .flex .right {
        text-align: right;
    }

    .col-table .flex .field {
        padding-right: 10px;
    }

@media (min-width: 1380px) {
    .col-table .flex .field {
        padding-right: 20px;
    }
}

.modal.small .modal-container {
    width: clamp(360px, 31.25%, 500px);
    padding: 3vw 3.88vw 25px;
    border-radius: 12px;
}

@media (min-width: 1380px) {
    .modal.small .modal-container {
        padding-bottom: 2vw;
    }
}

.modal.small .modal-container .text-holder {
    padding: 1em 0 2.75em;
    text-align: center;
}

.modal.small .modal-container .title-holder {
    color: #424242;
    font-size: 18px;
    padding: 1em 0 1.5em;
    font-weight: 500;
    text-align: center;
}

.modal.small .modal-container .btn-holder {
    display: flex;
    width: 100%;
    justify-content: center;
}

    .modal.small .modal-container .btn-holder > button {
        flex: 1;
        width: calc(50% - 16px);
        max-width: calc(50% - 16px);
        min-width: auto;
    }

        .modal.small .modal-container .btn-holder > button + button {
            margin-left: 16px;
        }

    .modal.small .modal-container .btn-holder .sp {
        justify-content: space-between;
    }

.modal.small .modal-container .content-holder {
    padding-bottom: 30px;
}

@media (min-width: 1700px) {
    .modal.small .modal-container .content-holder {
        padding-bottom: 40px;
    }
}

.modal.small.alert .icon-holder .icon:before {
    background-color: #F37402;
}

html,
body {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    image-rendering: -webkit-optimize-contrast;
    /* background: linear-gradient(to bottom, #5EB1E5 0%, #5EB1E5 100%);*/
    background: linear-gradient(to bottom, #FFFFFF 0%, #00499D 19.5%, #00042D 100%);
    min-height: 100vh;
    line-height: 1.75;
}

@media (min-width: 0) and (max-width: 739px) {
    html,
    body {
        line-height: 1.75;
    }
}

body {
    font-family: "Noto Sans TC", "PingFang", "PingFangTC", "Microsoft JhengHei", Helvetica, Arial, sans-serif;
    font-size: 16px;
    color: #424242;
}

@media (min-width: 0) and (max-width: 979px) {
    body {
        overflow: hidden;
        max-width: 100vw;
        max-width: 100vh;
    }

        body:before {
            content: "本服務尚不支援手機";
            display: block;
            width: 100%;
            height: 100%;
            position: fixed;
            display: flex;
            align-items: center;
            justify-content: center;
            left: 0;
            top: 0;
            background: linear-gradient(to bottom, #014089 0%, #241B6A 100%);
            font-size: 2rem;
            color: #fff;
            z-index: 999;
            box-sizing: border-box;
        }
}

*,
*:focus,
*:hover {
    outline: none;
}

a {
    text-decoration: none;
}

img {
    display: block;
    width: 100%;
    height: 50%;
    line-height: 0;
}

.img {
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

    .img:before {
        content: "";
        display: block;
        width: 100%;
        padding-bottom: 56%;
    }

section {
    max-width: 100vw;
}

@media (min-width: 740px) {
    .container {
        width: 100%;
        max-width: 720px;
        max-width: 600px;
        margin: 0 auto;
    }
}

@media (min-width: 1180px) {
    .container {
        max-width: 960px;
        margin: 0 auto;
    }
}

@media (min-width: 1700px) {
    .container {
        max-width: 1080px;
    }
}

@media (min-width: 980px) {
    .container.narrow {
        max-width: 600px;
        margin: 0 auto;
    }
}

@media (min-width: 1700px) {
    .container.narrow {
        max-width: 720px;
    }
}

@media (min-width: 740px) {
    .container.full {
        max-width: calc(100% - 80px);
    }
}

@media (min-width: 1180px) {
    .container.full {
        max-width: calc(100% - 80px);
    }
}


@media (min-width: 1700px) {
    .container.full {
        max-width: calc(100% - 80px);
    }
    .container.half {
        max-width:calc(50%);
    }
}


@media (min-width: 740px) {
    .container.mid {
        max-width: calc(100% - 80px);
    }
}

@media (min-width: 1180px) {
    .container.mid {
        max-width: calc(100% - 200px);
    }
}


@media (min-width: 1700px) {
    .container.mid {
        max-width: calc(100% - 480px);
    }
}

@media (min-width: 740px) {
    .containerB.mid {
        max-width: calc(100% - 80px);
    }
}

@media (min-width: 1180px) {
    .containerB.mid {
        max-width: calc(100% - 200px);
    }
}


@media (min-width: 1700px) {
    .containerB.mid {
        max-width: calc(100% - 480px);
    }
}

.hlogo {
    position: absolute;
    width: 266px;
    height: 53px;
    left: 60px;
    top: 5px;
    background: url("../images/logo.png");
}

hr {
    display: block;
    box-shadow: none;
    width: 100%;
    height: 1px;
    border: none;
    border-bottom: 1px solid #BDBDBD;
    margin: 2em auto 0em;
}

@media (min-width: 1180px) {
    hr {
        width: calc(100% - 30px);
    }
}

.copyright {
    text-align: center;
    color: #9E9E9E;
    border-top: 4px solid #BDBDBD;
    margin-top: 4vw;
    font-family: "Roboto", sans-serif;
    padding: 1em 0;
    font-size: .875rem;
}

.nav-container {
    display: flex;
    flex-direction: column;
    padding-top: 0;
    padding-bottom: 3em;
}

    .nav-container .item {
        position: relative;
        border-radius: 20px 0 0 20px;
    }

        .nav-container .item a {
            display: flex;
            align-items: center;
            padding: 0.7em 20px;
        }

        .nav-container .item .icon {
            margin-right: 0.5rem;
        }

            .nav-container .item .icon:before {
                background-color: #fff;
                transition: none;
            }

@media (min-width: 1700px) {
    .nav-container .item .icon:before {
        width: 32px;
        height: 32px;
    }
}

.nav-container .item span {
    color: #fff;
    transition: background-color .3s ease;
    line-height: 1.25em;
}

@media (min-width: 1700px) {
    .nav-container .item a {
        padding: 0.85em 20px;
    }

        .nav-container .item a br {
            display: none;
        }
}

@media (hover: hover) {
    .nav-container .item:hover {
        background-color: #FAFAFA;
    }

        .nav-container .item:hover .icon:before {
            /*background-color: #F37402;*/
            background-color: #000000;
        }

        .nav-container .item:hover span {
            color: #000000;
        }
}

.nav-container .item.active {
    background-color: #FAFAFA;
}

    .nav-container .item.active .icon:before {
        background-color: #000000;
    }

    .nav-container .item.active span {
        color: #000000;
    }

.nav-container .dropmenu {
    background-color: #FAFAFA;
    color: #616161;
    border-radius: 0 20px 20px 0;
    box-shadow: rgba(149, 157, 165, 0.2) 3px 8px 24px;
    left: 100%;
    top: 0;
    opacity: 0;
    pointer-events: none;
    opacity: 0;
    z-index: 3;
    max-height: 0;
    transition: none;
}

    .nav-container .dropmenu a {
        color: #616161;
        display: block;
        white-space: nowrap;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        position: relative;
        min-width: 8em;
        text-align: right;
    }

        .nav-container .dropmenu a:before {
            content: "";
            display: block;
            width: 100%;
            height: 1px;
            border-bottom: 1px solid #a7a7a7;
            position: absolute;
            width: calc(100%);
            left: 20px;
            top: 0;
        }
        /* .nav-container .dropmenu a:after {
            content: "";
            display: block;
            width: 100%;
            height: 1px;
            border-bottom: 1px solid #E0E0E0;
            position: absolute;
            width: calc(100% - 40px);
            left: 20px;
            bottom: 0;
        }*/

        .nav-container .dropmenu a:last-of-type:after {
            display: none;
        }

        .nav-container .dropmenu a:active, .nav-container .dropmenu .active {
            color: #F37402;
        }

        .nav-container .dropmenu a:active, .nav-container .dropmenu a:hover {
            color: #F37402;
        }

.nav-container .has-dropmenu {
    position: relative;
    overflow: hidden;
    font-size:16px;
}

    .nav-container .has-dropmenu.active .dropmenu {
        pointer-events: auto;
        transform: none;
        opacity: 1;
        max-height: 500px;
        transition: max-height .8s ease;
    }

.header {
    height: 72px;
    width: 100%;
    color: black;
    display: flex;
    justify-content: space-between;
    justify-content: space-between;
    align-items: center;
    position: relative;
    position: sticky;
    top: 0;
    z-index: 9;
    /*background-color: #5EB1E5;*/
    background-color: #FFFFFF 0%, #00499D 19.5%;
    font-weight:bolder;
    font-size:18px;
}

@media (min-width: 740px) {
    .header {
        padding: 0 40px 0 56px;
    }
}

.header a {
    /*color: #fff;*/
    color: black;
}

.header .logotxt {
    font-family: 'Noto Sans TC';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 46px;
    color: #000;
}

.header .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
}

@media (min-width: 1700px) {
    .header .logo {
        width: 190px;
    }
}

.header .logo .icon:before {
    width: 58px;
    height: 58px;
    background-color: #fff;
}

.header .time {
    padding-left: 2vw;
}

.header .fn-bar {
    width: calc(100% - 280px);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media (min-width: 1700px) {
    .header .fn-bar {
        width: calc(100% - 280px);
    }
}

.header .fn-bar a {
    display: inline-flex;
    align-items: center;
}

    .header .fn-bar a + a {
        margin-left: 26px;
    }

@media (hover: hover) {
    .header .fn-bar a {
        transition: color .15s ease;
    }

        .header .fn-bar a:hover {
            color: #62B8EE;
        }

            .header .fn-bar a:hover .icon:before {
                background-color: #62B8EE;
            }

        .header .fn-bar a.logout:hover {
            color: #F37402;
        }

            .header .fn-bar a.logout:hover .icon:before {
                background-color: #F37402;
            }
}

.header .fn-bar a.notice {
    position: relative;
}

    .header .fn-bar a.notice:before {
        content: "";
        display: block;
        width: 12px;
        height: 12px;
        background-color: #F37402;
        position: absolute;
        right: 8px;
        top: 4px;
        border-radius: 50%;
        z-index: 3;
        animation: notice ease 1.5s infinite forwards;
    }

.header .fn-bar .icon {
    margin-right: 5px;
}

    .header .fn-bar .icon:before {
        width: 36px;
        height: 36px;
        background-color: #fff;
    }

@keyframes notice {
    0% {
        transform: scale(1.1);
    }

    50% {
        transform: scale(0.6);
    }

    100% {
        transform: scale(1.1);
    }
}

.nav {
    width: 160px;
    height: calc(100vh - 72px - 2vw);
    overflow-y: auto;
    margin-top: 2vw;
}

    .nav::-webkit-scrollbar-track {
        -webkit-box-shadow: none;
        background-color: #19135A;
        border-radius: 5px;
        box-sizing: border-box;
    }

    .nav::-webkit-scrollbar {
        width: 8px;
        background-color: transparent;
        border-radius: 5px;
        margin-right: 20px;
    }

    .nav::-webkit-scrollbar-thumb {
        background-color: #327ee8;
        border-radius: 5px;
        border: none;
    }

@media (min-width: 1700px) {
    .nav {
        width: 300px;
    }
}

.main {
    width: 100%;
}

@media (min-width: 1180px) {
    .main {
        padding: 0 40px 0 26px;
        display: flex;
    }
}

.page-content {
    padding-top: 30px;
    background-color: #FAFAFA;
    width: calc(100% - 160px);
    height: calc(100vh - 72px);
    padding: 0;
    display: block;
    overflow-y: auto;
}

@media (min-width: 1180px) {
    .page-content {
        /*        border-radius: 2vw 2vw 0 0;
        padding: 24px 24px 0;
        padding: 0;
        display: flex;
        flex-direction: column;*/
        width: calc(100%);
        padding: 48px 48px 0;
        padding: 0;
    }
}

/*@media (min-width: 1700px) {

    .page-content {
        width: calc(100%);
        padding: 48px 48px 0;
        padding: 0;
    }
}*/

.page-content .title-area .containerB {
    display: flex;
    align-items: flex-end;
    padding: 2.5em 0 1.5em;
    margin: auto;
}

    .page-content .title-area .containerB .fz-A,
    .page-content .title-area .containerB .fz-B {
        margin-bottom: 8px;
    }

@media (min-width: 1380px) {
    .page-content .title-area .containerB .fz-A,
    .page-content .title-area .containerB .fz-B {
        margin-bottom: 16px;
    }
}

.page-content .title-area .container {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 2.5em 0 1.5em;
}

    .page-content .title-area .container .fz-A,
    .page-content .title-area .container .fz-B {
        margin-bottom: 8px;
    }

@media (min-width: 1380px) {
    .page-content .title-area .container .fz-A,
    .page-content .title-area .container .fz-B {
        margin-bottom: 16px;
    }
}

.page-content .footer {
    width: calc(100% - 50px);
    margin-left: auto;
    margin-right: auto;
}

.full {
    width: 100%;
}

    .full.center {
        display: flex;
        align-items: center;
        justify-content: center;
    }

.dropmenu-container {
    position: relative;
    width: 100%;
    margin: 0 !important;
}

    .dropmenu-container .dropmenu-value {
        border: 1px solid #BDBDBD;
        font-size: 1rem;
        transition: border-color .3s ease;
        height: 32px;
        box-sizing: border-box;
        margin: 0;
        border-radius: 20px;
        display: flex;
        align-items: center;
        width: 100%;
        padding-left: 1em;
        padding-right: 1em;
        background: #fff url("../images/layout/arrow-select.svg") no-repeat right 16px center;
        background-size: 15px auto;
        cursor: pointer;
    }

@media (min-width: 1700px) {
    .dropmenu-container .dropmenu-value {
        height: 36px;
    }
}

.dropmenu-container .dropmenu-value.focus {
    border-color: #333;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.dropmenu-container .dropmenu-value.disabled {
    background-color: rgba(224, 224, 224, 0.6);
    color: #888;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    cursor: not-allowed;
}

@media (min-width: 1700px) {
    .dropmenu-container .dropmenu-value {
        height: 36px;
    }
}

.dropmenu-container .dropmenu {
    position: absolute;
    background-color: #fff;
    width: 100%;
    padding: 5px 0 1em;
    border-radius: 8px;
    transition: transform .2s ease;
    opacity: none;
    visibility: hidden;
    pointer-events: none;
    max-height: 360px;
    overflow-y: auto;
}

    .dropmenu-container .dropmenu::-webkit-scrollbar-track {
        -webkit-box-shadow: none;
        background-color: #eee;
        border-radius: 5px;
        border: 3px solid #fff;
        box-sizing: border-box;
    }

    .dropmenu-container .dropmenu::-webkit-scrollbar {
        width: 8px;
        background-color: #eee;
        border-radius: 5px;
    }

    .dropmenu-container .dropmenu::-webkit-scrollbar-thumb {
        background-color: #ccc;
        border-radius: 5px;
        border: none;
    }

    .dropmenu-container .dropmenu li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 1em;
    }

@media (hover: hover) {
    .dropmenu-container .dropmenu li {
        transition: background-color .1s ease;
    }

        .dropmenu-container .dropmenu li:hover {
            background-color: rgba(189, 189, 189, 0.1);
        }
}

.dropmenu-container .dropmenu [data-value] {
    cursor: pointer;
    flex: auto;
    padding: 0.2em 0;
}

.dropmenu-container .dropmenu .fn .icon {
    pointer-events: none;
}

.dropmenu-container .additional {
    border-top: 1px solid #E0E0E0;
    padding: 0.5em 1em;
    margin-top: 0.5em;
}

    .dropmenu-container .additional a {
        color: #62B8EE;
        border-bottom: 1px solid #62B8EE;
    }

.dropmenu-container.active {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border-radius: 8px 8px 0 0;
    z-index: 9;
}

    .dropmenu-container.active .dropmenu-value {
        border-top: #fff;
        border-radius: 8px 8px 0 0;
        background-color: #fff;
        border: none;
    }

    .dropmenu-container.active .dropmenu {
        box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 12px;
        border-radius: 0 0 8px 8px;
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
    }

.login {
    background: none;
    background: #88c8db;
    background-size: 100% auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-box {
    max-width: 578px;
    width: 80%;
}

    .login-box .title {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        padding-bottom: 8vh;
    }

        .login-box .title .icon {
            margin-right: 15px;
        }

            .login-box .title .icon:before {
                width: 80px;
                height: 80px;
                background-color: #fff;
            }

    .login-box .subtitle {
        text-align: center;
    }

        .login-box .subtitle .fz-B {
            font-weight: 500;
        }

        .login-box .subtitle .fz-C {
            font-weight: 500;
        }

.dashboard-header {
    height: 200px;
    padding: 30px 100px 30px;
}

@media (min-width: 1380px) {
    .dashboard-header {
        height: 250px;
        padding: 70px 100px 32px;
    }
}

.dashboard-header > div {
    color: #fff;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #F5F5F5;
    padding-bottom: 24px;
}

.dashboard-header .left {
    display: flex;
}

    .dashboard-header .left .title {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

        .dashboard-header .left .title .icon:before {
            width: 90px;
            height: 90px;
            background-color: #fff;
        }

        .dashboard-header .left .title h1 {
            font-size: 16px;
        }

.dashboard-header .welcome {
    padding-left: 80px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

    .dashboard-header .welcome .fz-A {
        margin-bottom: 0;
        font-weight: 500;
    }

    .dashboard-header .welcome .fz-B {
        margin-bottom: 0.5em;
    }

.dashboard-header .right {
    padding: 20px 34px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}

@media (hover: hover) {
    .dashboard-header .btn-small:hover {
        color: #fff;
        background-color: #F37402;
        border-color: #F37402;
    }
}

.dashboard-header .btn-logout {
    display: flex;
    align-items: center;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    cursor: pointer;
}

    .dashboard-header .btn-logout span {
        margin-left: 5px;
        transition: color .3s ease;
    }

    .dashboard-header .btn-logout .icon:before {
        background-color: #fff;
        width: 32px;
        height: 32px;
    }

@media (hover: hover) {
    .dashboard-header .btn-logout:hover {
        color: #F37402;
    }

        .dashboard-header .btn-logout:hover .icon:before {
            background-color: #F37402;
        }
}

.dashboard-body {
    background-color: #FAFAFA;
    width: 100%;
    height: calc(100vh - 200px);
    overflow: hidden;
    padding: 0;
}

    .dashboard-body .scrollbar {
        overflow-y: auto;
        height: calc(100vh - 200px);
    }

@media (min-width: 1380px) {
    .dashboard-body .scrollbar {
        height: calc(100vh - 250px);
    }
}

.dashboard-body .container {
    display: flex;
    padding: 40px 58px 0;
    width: 100%;
    max-width: 100%;
    flex-wrap: wrap;
}

.dashboard-body .left {
    width: 29%;
    overflow-y: auto;
    height: 100%;
    padding-right: 40px;
}

    .dashboard-body .left .title {
        display: flex;
        align-items: center;
        padding-bottom: 47px;
    }

        .dashboard-body .left .title .icon:before {
            width: 40px;
            height: 40px;
            background-color: #F37402;
        }

        .dashboard-body .left .title .fz-B {
            margin-bottom: 0;
            margin-left: 0.5em;
        }

.dashboard-body .right {
    width: 71%;
    border-left: 1px solid #BDBDBD;
    padding-left: 40px;
}

@media (min-width: 1700px) {
    .dashboard-body .right {
        padding-left: 60px;
    }
}

.dashboard-body .bottom {
    width: 100%;
}

@media (min-width: 740px) {
    .dashboard-body {
        border-radius: 2vw 2vw 0 0;
    }
}

@media (min-width: 1380px) {
    .dashboard-body {
        height: calc(100vh - 250px);
    }
}

@media (min-width: 1700px) {
    .dashboard-body {
        width: 100%;
    }
}

.dashboard-body .section {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -12px;
    overflow: hidden;
}

    .dashboard-body .section.with-border:before {
        content: "";
        display: block;
        width: 100%;
        margin-bottom: 40px;
        border-top: 1px solid #BDBDBD;
    }

    .dashboard-body .section.for-admin {
        align-items: flex-start;
        justify-content: space-between;
    }

.notice-item {
    display: block;
    color: #424242;
    border-bottom: 1px solid #E0E0E0;
    margin-bottom: 24px;
}

    .notice-item .fn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 24px;
    }

    .notice-item h6 {
        padding-bottom: 1em;
    }

    .notice-item .date {
        color: #757575;
        font-family: "Roboto", sans-serif;
    }

.db-item {
    display: flex;
    flex-direction: column;
    width: calc(50% - 24px);
    margin: 0 12px 16px;
    color: #777;
    border: 1px solid #E5E6E9;
    border-radius: 20px;
    padding: 16px 24px;
    background: linear-gradient(180deg, #F5F5F5 17.71%, #DDDDDD 88.54%, #CDCDCD 100%);
}

@media (min-width: 980px) {
    .db-item {
        width: calc(33.33% - 24px);
        padding: 1.4vw 1.6vw 1.2vw;
    }
}

@media (min-width: 1380px) {
    .db-item {
        width: calc(25% - 24px);
    }
}

.db-item .icon:before {
    width: 36px;
    height: 36px;
    background-color: #9E9E9E;
    transition: none;
}

@media (min-width: 1700px) {
    .db-item .icon:before {
        width: 45px;
        height: 45px;
    }
}

.db-item .fz-C {
    margin-bottom: 0;
    margin-top: 12px;
    text-align: right;
}

.db-item.small {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

    .db-item.small .fz-C {
        margin-top: 0;
        margin-bottom: 2px;
        white-space: nowrap;
    }

@media (hover: hover) {
    .db-item:hover {
        background: linear-gradient(#62B8EE, #559ecc);
        color: #fff;
        border-color: #62B8EE;
    }

        .db-item:hover .icon:before {
            background-color: #fff;
        }
}

.dashboard .full {
    width: 100%;
    padding: 0 20px;
}

.dev {
    margin-top: 60px;
}

    .dev .container {
        background-color: #efefef;
        padding: 30px;
        border-radius: 5px;
        border: 1px solid #ccc;
    }

        .dev .container:after {
            content: "白色：figma上標示  |  紅色：figma下方標註  |  灰色：曾經在前面單元出現過 ";
            display: block;
            color: #666;
            letter-spacing: 0.5px;
            font-size: .875rem;
        }

    .dev h3 {
        margin-bottom: 1em;
    }

    .dev .btn {
        height: 30px;
        font-size: 14px;
        width: 140px;
        min-width: auto;
        border: 2px solid #666;
        background-color: #fff;
        border-radius: 3px;
        margin: 0 15px 15px 0;
    }

        .dev .btn span {
            font-family: "PingFang", "PingFangTC", "Microsoft JhengHei", Helvetica, Arial, sans-serif;
            font-weight: bold;
            color: #666;
        }

        .dev .btn.red {
            background-color: #FF0000;
            border-color: #FF0000;
        }

            .dev .btn.red span {
                color: #fff;
            }

        .dev .btn.ed {
            background-color: #888;
            border-color: #888;
        }

            .dev .btn.ed span {
                color: #eee;
            }

        .dev .btn:hover, .dev .btn:active {
            background-color: #333;
            border-color: #333;
        }

            .dev .btn:hover span, .dev .btn:active span {
                color: #fff;
            }

.mr-1 {
    margin-right: 1em;
}

.mr-2 {
    margin: .2em 0 0.2rem;
    font-weight: bolder;
}

.ml-1{
    margin-left: 1rem;
}
.ml-2{
    margin-left:2rem;
}
.mt-1 {
    margin-top: 1rem;
}
.my-1 {
    margin-top: 1rem ;
    margin-bottom: 1rem ;
}
.fw-b {
    font-weight: bolder !important;
}
/* items水平對齊 */
.fc-1 {                                 
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.top-half {
    width: calc(50%);
}

.top-right {
    position: absolute;
    right: 0px;
    padding: 2px;
}

.text-red {
    color: #dc3545;
}
.text-right{
    text-align:right;
}
.text-left {
    text-align: left;
}
.text-center {
    text-align: center;
}
.multi-inline {
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
}
.form-title {
    display: flex;
    flex-wrap: wrap;
    width:100% ;
}
.wp9{
    width:90%;
}
.w1{
    width:25rem;
}

.date-item-l {
    margin-right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.date-item-r {
    margin-right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.form-border {
    border-color: lightgray;
    border-style: solid;
    border-width: 1px;
    padding: 15px 5px;
}

.ck.ck-content {
    height: 200px;
}
.discount-div {
    display: flex;
    align-items: center;
    border-right-width: 1px;
    border-right-color: #a1a1a1;
    border-right-style: solid;
    justify-content: space-between;
    /*max-width: max-content;*/
}
.preview-fields {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-content: center;
    /* justify-content: center; */
    align-items: flex-end;
}
.preview-form {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
}
.preview-img{
    max-Height:1000px;
    max-Width: 1000px;
}
.preview-modal {
    padding: 0px;
    width: clamp(880px, 50%, 1080px) !important;
}
.preview-closebtn {
    display: flex;
    justify-content: flex-end;
    padding: 5px;
    
}
.pointer{
    cursor:pointer;
}
.h-full{
   height:100%;
}
.boxes {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-auto-rows: 60px; /* 設定高度為100PX*/
    font-size: 14px;
    border: 1px solid #E0E0E0;
   
}
    .boxes div {
        border-bottom: 1px solid #E0E0E0;
        padding: 12px 13px;
    }
        .boxes div:nth-last-child(-n+2) {
            border: none;
            padding: 12px 13px;
        }
.CouImgDiv {
    width: 220px;
    margin: 0px;
    height: 220px;
    padding-top: 20px;
    border: #000000 solid 2px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
}
.CouImgXX {
    position: absolute;
   
    top: 25px;
    background-color: #b5bdba9e;
    height: 25px;
    width: 25px;
    text-align-last: center;
    border-radius: 15px;
    cursor: pointer;
}



.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.page-link {
    text-decoration: none;
    padding: 8px 15px;
    margin: 0 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    color: #333;
    transition: background-color 0.3s, color 0.3s;
}

    .page-link.active {
        background-color: #007bff;
        color: #fff;
        border: 1px solid #007bff;
    }

    .page-link:hover {
        background-color: #eee;
    }

.page-select {
    width: 5%;
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 5px;
    color: #333;
}
.cursor-pointer {
    cursor: pointer
}
.margin-right-3{
    margin-right: 20px;
}
.sign-up-card {
    /*padding: 10px 0px;*/
    border-bottom: 1px solid #424242;
    border-style: dashed;
    /*border-radius: 20px 20px;*/
    /*background: rgba(255, 0, 0, 0);*/
    margin-bottom: 20px;
}
    .sign-up-card:hover {
    /*border: 1px solid gray;*/
    /*background: #62B8EE;*/
}
.bd-dashed {
    border-style: dashed;
}

.loading-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3); /* 淺灰色背景，透明度為 50% */
    z-index: 999; /* 設置 z-index 使其位於最上層 */
}

.loading-screen {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000; /* 設置 z-index 比背景更高，使其覆蓋在最上層 */
}

.spinner {
    width: 50px;
    height: 50px;
    border: 5px solid #007bff; /* 輪播器顏色 */
    border-radius: 50%;
    border-top-color: transparent;
    animation: spin 1s linear infinite; /* 旋轉動畫 */
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}